import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import ParallaxImage from "../components/parallaxImage";
import Layout from "../components/layout";
import SafeZone from "../components/safeZone";
import SEO from "../components/seo";

const Cookies = ({
  data: {
    allPrismicCookies: {
      edges: [
        {
          node: {
            data: {
              policy: { raw },
            },
          },
        },
      ],
    },
  },
}) => (
  <>
    <SEO title="Cookies" />
    <Layout>
      <ParallaxImage
        height="halfScreen"
        url="https://images.unsplash.com/photo-1504192010706-dd7f569ee2be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80"
      />
      <SafeZone>{RichText.render(raw)}</SafeZone>
      <ParallaxImage
        height="halfScreen"
        url="https://images.unsplash.com/photo-1504192010706-dd7f569ee2be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80"
      />
    </Layout>
  </>
);

Cookies.propTypes = {
  data: PropTypes.node,
};

Cookies.defaultProps = {
  data: null,
};

export default Cookies;

export const query = graphql`
  query {
    allPrismicCookies {
      edges {
        node {
          data {
            policy {
              raw {
                type
                text
                spans {
                  data {
                    link_type
                    url
                  }
                  end
                  start
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;
